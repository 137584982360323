<template>
	<vue-plyr
		class="plyr-wrapper"
		ref="plyr"
		:options="opts"
		:emit="[
			'playing',
			'play',
			'pause',
			'ended',
		]"

		@play="handlePlay"
		@playing="handlePlay"
		@pause="handlePause"
		@ended="handlePause"

		v-bind="$attrs"
		v-on="$listeners"
	>
		<video
			v-if="videoData.src"
			:src="videoData.src"
			:poster="localPoster"
			:preload="preload"
			@loadeddata="onVideoLoaded"
		/>

		<div v-else class="plyr__video-embed">
			<iframe
				:src="videoData.iframe_src"
				allowfullscreen
				allowtransparency
				allow="autoplay"
			/>
		</div>

		<transition name="button-fade" >
			<button
				v-if="playButton && !isPlaying"
				type="button"
				class="plyr-wrapper__large-paly-button"
				@click="() => player && player.play()"
			>
				<span class="hidden-captiopn">Play</span>
				<svg class="play-glyph" width="89" height="104">
					<use xlink:href="#glyph--play-triangle" href="#glyph--play-triangle" />
				</svg>
			</button>
		</transition>

		<slot />
	</vue-plyr>
</template>

<script>
import { resolveRelativeStaticURL } from "@/utilites.js";

/*
 * Regular expressions
 */
const RE_VIDEO_FILE_TYPE = /\.(webm|mp4|ogg|mov)(\?.*)?$/i;
const RE_IS_YOUTUBE = /^https?:\/\/(www\.)?youtu(\.be|be\.com)\//i;
const RE_YOUTUBE_ID = /(\/embed\/|\?v=|\?.*&v=|\/\/youtu\.be\/)(\w+)/i;
const RE_IS_VIMEO = /^https?:\/\/(www|player\.)?vimeo\.com\//i;
const RE_VIMEO_ID = /\/\/(vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/i;

const PLYR_OPTIONS_MIXIN = Object.freeze({
	controls: [
		'play',
		'progress',
		'mute',
		'volume',
		'fullscreen'
	],
	displayDuration: false,
	fullscreen: {
		enabled: true,
		iosNative: true
	}
});

export default {
	name: 'PlyrWrapper',

	props: {
		src: {
			type: String,
			required: true
		},

		poster: {
			type: String
		},

		preload: {
			type: String,
			default: 'metadata'
		},

		options: {
			type: Object,
			default: () => ({})
		},

		playButton: Boolean,
	},

	data() {
		return {
			localPoster: this.poster,
			isPlaying: false,
		};
	},

	computed: {
		player() {
			return this.$refs.plyr.player || null;
		},

		opts() {
			return {
				...PLYR_OPTIONS_MIXIN,
				...this.options
			};
		},

		videoData() {
			if (!this.src) return null;

			const url = this.src;

			// youtube
			if (RE_IS_YOUTUBE.test(url)) {
				let id = RE_YOUTUBE_ID.exec(url)[2];
				return {
					id,
					iframe_src: `https://www.youtube.com/embed/${id}?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1`,
					raw: url,
					type: "youtube"
				};
			}

			// vimeo
			if (RE_IS_VIMEO.test(url)) {
				let id = RE_VIMEO_ID.exec(url)[2];
				return {
					id,
					iframe_src: `https://player.vimeo.com/video/${id}?loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`,
					raw: url,
					type: "vimeo"
				};
			}

			// video file
			const suffix = RE_VIDEO_FILE_TYPE.exec(url)[1];
			return {
				src: url,
				type: suffix ? `video/${suffix.toLowerCase()}` : "video/mp4"
			};
		}
	},

	methods: {
		async getFrame(video) {
			// generate poster
			const width = video.videoWidth;
			const height = video.videoHeight;
			const canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;
			const ctx = canvas.getContext("2d");

			return await new Promise((resolve, reject) => {
				ctx.drawImage(video, 0, 0, width, height);
				try {
					canvas.toBlob(
						blob => {
							resolve(URL.createObjectURL(blob));
						},
						"image/jpg",
						0.95
					);
				} catch (error) {
					reject(error);
				}
			});
		},

		onVideoLoaded(event) {
			if (this.poster || this.localPoster) {
				return;
			}

			this.getFrame(event.target)
				.then(image => {
					this.localPoster = image;

					if (this.player) {
						this.player.poster = image;
					}
				})
				.catch(error => {
					if (process.env.NODE_ENV === "development") {
						console.error("videoloadedhandler: ", error);
					}
				});
		},

		handlePlay() {
			this.isPlaying = true;
		},

		handlePause() {
			this.isPlaying = false;
		}
	},

	mounted() {
		if (this.player) {
			this.player.poster = this.localPoster;
			//this.player.fullscreen.enter()
		}
	}
};
</script>

<style lang="sass" scoped>
// .plyr-wrapper
::v-deep .plyr.plyr--paused .plyr__controls
	display: none
::v-deep .plyr__poster
	background-size: cover

.hidden-captiopn
	@extend %visually-hidden

.plyr-wrapper__large-paly-button
	@extend %clean-button
	display: block
	position: absolute

	cursor: pointer
	width: 89px
	height: 104px
	left: calc((100% - 89px) / 2)
	top: calc((100% - 104px) / 2)
	color: #ffffff


.play-glyph
	width: 89px
	height: 104px


.button-fade-enter,
.button-fade-leave-to
	opacity: 0

.button-fade-enter-active
	transition: opacity .2s ease .3s

.button-fade-leave-active
	transition: opacity .2s ease
</style>
